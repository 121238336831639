<script lang="ts">
	import { PUBLIC_API_BASE_URL as API_BASE_URL } from '$env/static/public';
	import { goto } from '$app/navigation';
	import { Check, Copy, Loader2 } from 'lucide-svelte';
	import { browser } from '$app/environment';
	import { onDestroy } from 'svelte';
	import { inview } from 'svelte-inview';

	let className: string | undefined = undefined;
	export { className as class };
	export let forceVertical = false;

	let email = `d+${' '.repeat(12)}@dmarcwise.email`;
	let token = '';
	let copied = false;
	let timeout: ReturnType<typeof setTimeout>;
	let isVisible = false;

	function copyToClipboard() {
		if (copied) return;
		navigator.clipboard.writeText(email);
		copied = true;
		setTimeout(() => {
			copied = false;
		}, 2000);
	}

	async function getToken() {
		let res;
		try {
			res = await fetch(`${API_BASE_URL}/diagnoses`, {
				method: 'POST'
			});
		} catch (e) {
			console.error(e);
			email = 'Oops, error. Try refreshing.';
			return;
		}

		if (!res.ok) {
			email = 'Oops, error. Try refreshing.';
			return;
		}

		const data = await res.json();
		token = data.diagnosisToken;
		email = data.diagnosisEmailAddress;

		timeout = setTimeout(check, 10 * 1000);
	}

	async function check() {
		if (!isVisible) {
			scheduleCheck();
			return;
		}

		let res: Response;
		try {
			res = await fetch(`${API_BASE_URL}/diagnoses/${token}`, {
				method: 'GET'
			});
		} catch (e) {
			console.error(e);
			scheduleCheck();
			return;
		}

		if (res.status === 404) {
			scheduleCheck();
			return;
		}

		if (!res.ok) {
			return;
		}

		await goto(`/diagnoses/${token}`);
	}

	function scheduleCheck() {
		timeout = setTimeout(check, 3 * 1000);
	}

	if (browser) {
		getToken();
	}

	onDestroy(() => {
		clearTimeout(timeout);
	});
</script>

<div
	use:inview
	on:inview_change={(event) => {
		const {inView} = event.detail;
		isVisible = inView;
	}}
	class="{className} bg-green-50 rounded-lg px-8 md:py-14 py-10 items-center text-center grid {!forceVertical ? 'lg:grid-cols-2': ''} gap-x-10 gap-y-8">
	<!-- Left -->
	<div>
		<h2 class="text-2xl font-semibold">
			Check if your domain is protected.
		</h2>

		<p class="mt-4 md:mt-3 sm:px-4 text-green-750">
			Send a test email to check your
			<mark class="green font-semibold">SPF</mark>
			,
			<mark class="green font-semibold">DKIM</mark>
			and
			<mark class="green font-semibold">DMARC</mark>
			compliance.
		</p>
	</div>

	<!-- Right -->
	<div class="flex flex-col items-center gap-y-5 w-full overflow-hidden">
		<button
			class="group max-w-full bg-white rounded-lg px-5 py-3 font-mono text-lg text-green-750 border border-green-100 flex items-center gap-x-4 cursor-pointer"
			aria-label="Copy email address"
			on:click={copyToClipboard}>
			<span class="truncate whitespace-pre">
				{email}
			</span>
			{#if copied}
				<Check class="size-5 shrink-0 text-green-750" />
			{:else}
				<Copy class="size-5 shrink-0 text-slate-400 group-hover:text-green-750" />
			{/if}
		</button>

		<p class="flex items-center justify-center gap-x-2 text-green-750 font-medium">
			<Loader2 class="size-6 animate-spin" />
			Waiting for your email...
		</p>
	</div>
</div>
